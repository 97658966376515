import {
  AccountCircle as AccountCircleIcon,
  Email as EmailIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
  PhoneAndroid,
  PhoneIphone,
} from '@mui/icons-material';
import {
  Box,
  Hidden,
  List,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Modals,
  Routes,
  RoutesConfig,
  RouteType,
  SidebarItems,
  urls,
} from '@/constants';
import {
  AssessmentIcon,
  DirectionsIcon,
  ExploreIcon,
  FlagIcon,
  LumiQPictureMarkIcon,
  MembersIcon,
  ViewModuleIcon,
} from '@/icons';
import { AnalyticsService, BUTTON_NAME } from '@/services';
import {
  selectSessionUserId,
  selectUserProfileResult,
  uiActions,
} from '@/store';
import { getRedirectSearchParams, logout } from '@/utils';

const SidebarIcons = {
  [SidebarItems.LEARNING_PATHS.PATH]: <DirectionsIcon />,
  [SidebarItems.TECHNICAL.PATH]: <AssessmentIcon />,
  [SidebarItems.DISCOVER.PATH]: <ExploreIcon />,
  [SidebarItems.LIBRARY.PATH]: <ViewModuleIcon />,
  [SidebarItems.TRACKER.PATH]: <FlagIcon />,
  [SidebarItems.MY_LUMIQ.PATH]: <LumiQPictureMarkIcon />,
};

export const SidebarList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userId = useSelector(selectSessionUserId);

  const onListItemClick = (item: RouteType) => {
    dispatch(uiActions.toggleSidebarDrawer());
    navigate(item.PATH);
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  const isAndroid = navigator.userAgent.toLowerCase().includes('android');

  const { data: profile } = useSelector(selectUserProfileResult);
  const isUserManagementAdmin = profile?.memberRoles?.some(
    r => r.roleId === 'member_admin',
  );

  return (
    <List sx={{ pt: 0, pb: 10, color: 'text.white' }}>
      {Object.values(SidebarItems).map(item => {
        const isSelected = pathname === item.PATH;
        const Icon = SidebarIcons[item.PATH];

        return (
          <SidebarItem
            key={item.PATH}
            Icon={Icon}
            name={item.NAME}
            isSelected={isSelected}
            onClick={() => onListItemClick(item)}
          />
        );
      })}
      <Hidden mdUp>
        {userId ? (
          <>
            <SidebarItem
              Icon={<AccountCircleIcon />}
              name={RoutesConfig.PROFILE.NAME}
              isSelected={pathname === RoutesConfig.PROFILE.PATH}
              onClick={() => {
                onListItemClick(RoutesConfig.PROFILE);
              }}
            />
            <SidebarItem
              Icon={<LogoutIcon />}
              name="Log Out"
              onClick={logout}
            />
          </>
        ) : (
          <SidebarItem
            Icon={<LoginIcon />}
            name="Log In"
            onClick={() =>
              navigate({
                pathname: Routes.LOGIN,
                search: getRedirectSearchParams(),
              })
            }
          />
        )}
        <SidebarItem
          Icon={<EmailIcon />}
          name="Support"
          onClick={() => {
            dispatch(uiActions.toggleSidebarDrawer());
            window.Intercom?.('show');
          }}
        />
      </Hidden>
      <SidebarItem
        Icon={isAndroid ? <PhoneAndroid /> : <PhoneIphone />}
        name="Download App"
        onClick={() => {
          AnalyticsService.buttonClick({ name: BUTTON_NAME.DOWNLOAD_APP });

          dispatch(uiActions.toggleSidebarDrawer());
          if (isMobile) {
            window.open(urls.downloadApp);
            return;
          }

          dispatch(uiActions.setActiveModal({ name: Modals.APP_DOWNLOAD }));
        }}
      />

      {isUserManagementAdmin && (
        <>
          <Typography
            variant="body2"
            textTransform="uppercase"
            fontWeight="bold"
            mt={2}
            py={2}
            pl={3}>
            Admin Tools
          </Typography>
          <SidebarItem
            Icon={<MembersIcon />}
            name="Members"
            isSelected={pathname === RoutesConfig.MEMBER_MANAGEMENT.PATH}
            onClick={() => onListItemClick(RoutesConfig.MEMBER_MANAGEMENT)}
          />
        </>
      )}
    </List>
  );
};

const SidebarItem = ({
  isSelected,
  Icon,
  onClick,
  name,
}: {
  isSelected?: boolean;
  Icon: any;
  onClick: any;
  name: string;
}) => (
  <Box
    onClick={onClick}
    sx={[
      {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        py: 2,
        pl: 3,
        cursor: 'pointer',
        '&:hover': {
          bgcolor: 'action.hover',
        },
      },
      !!isSelected && {
        bgcolor: 'blueBlackDark',
        borderColor: 'aqua',
      },
    ]}>
    <Box
      sx={{
        position: 'absolute',
        width: 6,
        left: 0,
        top: 0,
        bottom: 0,
        bgcolor: 'aqua',
        zIndex: 1,
        display: isSelected ? 'block' : 'none',
      }}
    />
    <Box
      sx={{
        mr: 1,
        display: 'flex',
        alignItems: 'center',
        color: isSelected ? 'aqua' : 'text.white',
      }}>
      {Icon}
    </Box>
    <Box>
      <Typography
        variant="body2"
        sx={
          [
            !isSelected && { color: 'neutral10' },
            isSelected && { fontWeight: 700 },
          ] as SxProps<Theme>
        }>
        {name}
      </Typography>
    </Box>
  </Box>
);
