import { Tooltip, Typography } from '@mui/material';

import { timeSince, utcToLocalDateTime } from './utils';

export type Props = {
  date: string | null;
};

export const UserFriendlyDateWithTooltip = ({ date }: Props) => {
  if (date === null) {
    return <span>-</span>;
  }
  return (
    <Tooltip title={utcToLocalDateTime(date)}>
      <Typography variant="body3">{timeSince(date)}</Typography>
    </Tooltip>
  );
};
