import { Box, Skeleton } from '@mui/material';

export const SkeletonTable = ({ rows }: { rows: number }) => {
  return (
    <>
      {Array.from({ length: rows }).map((_, i) => (
        <Box component="tr" key={i} width="100%">
          <td colSpan={5} style={{ padding: '10px 16px 6px' }}>
            <Skeleton variant="rectangular" height={44} width="100%" />
          </td>
        </Box>
      ))}
    </>
  );
};
