import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Input, Theme } from '@mui/material';
import { useRef } from 'react';

import { useDebouncedCallback } from '@/hooks';

export type Props = { placeHolder: string; onSearch: (search: string) => void };

export const TableSearchBox = ({ placeHolder, onSearch }: Props) => {
  const inputRef = useRef<HTMLInputElement>();

  const debouncedSearch = useDebouncedCallback(onSearch, 250);

  return (
    <Box sx={styles.searchInputContainer}>
      <Input
        disableUnderline
        sx={styles.searchInput}
        startAdornment={
          <SearchIcon
            onClick={() => {
              setTimeout(() => {
                inputRef.current?.focus();
              }, 0);
            }}
          />
        }
        onChange={evt => debouncedSearch(evt.target.value)}
        inputRef={inputRef}
        placeholder={placeHolder}
      />
    </Box>
  );
};

const styles = {
  searchInputContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 300,
  },
  searchInput: (theme: Theme) => ({
    input: { color: 'text.black' },
    transition: '0.5s all ease',
    width: 20,
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.neutral20,
    pl: 1,
    '&>svg': {
      mr: 1,
    },
    '&.Mui-focused': {
      backgroundColor: 'white',
      width: 300,
      '&>svg': {
        color: 'blueBlack',
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: 300,
      backgroundColor: theme.palette.white,
      '&.Mui-focused': {
        backgroundColor: theme.palette.white,
        width: 300,
        '&>svg': {
          color: theme.palette.blueBlack,
        },
      },
    },
  }),
};
