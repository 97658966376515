import { GrowthBook } from '@growthbook/growthbook-react';
import mixpanel from 'mixpanel-browser';

import config from '@/config';
import { urls } from '@/constants';
import { Plan, Region, UserProfile } from '@/types';
import { ageInHours } from '@/utils';

import { AnalyticsService } from '../analytics';

export const growthbook = new GrowthBook({
  apiHost: urls.actions.growthbook,
  clientKey: config.GROWTHBOOK_CLIENT_CODE,
  enableDevMode: process.env.NODE_ENV !== 'production',
  trackingCallback: (experiment, result) => {
    console.log(
      // no-dd-sa:typescript-node-security/log-sensitive-data
      `
       GrowthBook Experiment ${experiment.key},
       UserId: ${result.hashValue}, 
       In Experiment: ${result.inExperiment}, 
       User Bucket: ${result.bucket}, 
       Variant: ${result.name},
       Variant Value: ${result.value}
       `,
      {
        experiment,
        result,
      },
    );

    AnalyticsService.updateFeatureFlags({
      [`$feature - ${result.featureId}`]: result.value,
    });

    mixpanel.track('$experiment_started', {
      'Experiment name': experiment.key,
      'Variant name': result.variationId,
      'Variant value': result.value,
      $source: 'growthbook',
    });
  },
});

// Note: growthbook will fail to load in test environments
if (process.env.NODE_ENV !== 'test') {
  growthbook.init({ streaming: true });
}

interface InitProps {
  userId: string;
  userProfile?: UserProfile;
  plan?: Plan;
  userRegion?: Region;
  userTags?: string[];
  registrationState?: boolean;
}

export function initGrowthbook({
  userId,
  userProfile,
  plan,
  userRegion,
  userTags,
  registrationState,
}: InitProps) {
  growthbook.setAttributes({
    id: userId,
    region: userRegion,
    userTags,
    application: 'web-client',
    url: window.location.href,
    userAgent: navigator.userAgent,
    ...(userProfile
      ? {
          email: userProfile.email,
          signupDate: userProfile.userCreatedAt,
          signupAge: ageInHours(userProfile.userCreatedAt) || 0,
          onboardingDate: userProfile.onboardingCompletedAt,
          onboardingAge: ageInHours(userProfile.onboardingCompletedAt) || 0,
          memberRoles: userProfile.memberRoles?.map(r => r.roleId),
        }
      : {}),
    ...(registrationState ? { registrationState } : {}),
    ...(plan ? { organizationName: plan.organizationName } : {}),
  });
}
