import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { TableSearchBox } from '@/components/TableSearchBox';
import {
  OrganizationMember,
  useOrganizationMembersQuery,
} from '@/store/member-management-api';

import { urls } from '../../constants';
import { selectUserProfileResult } from '../../store';
import { CsvDownload } from './CsvDownload';
import { MemberState } from './MemberState';
import { FilterState, MemberStateFilters } from './MemberStateFilters';
import { SkeletonTable } from './SkeletonTable';
import { UserFriendlyDateWithTooltip } from './UserFriendlyDateWithTooltip';

type SortDirection = 'asc' | 'desc';
type SortColumn =
  | 'name'
  | 'user_email'
  | 'state'
  | 'last_user_listening'
  | 'organization_member_created_at';

const searchMembers = (searchPhrase: string, data: OrganizationMember[]) => {
  if (searchPhrase.trim() === '') {
    return data;
  }

  return data.filter(m =>
    [m.name, m.user_email, m.organization_email].some(v =>
      v?.toString().toLowerCase().includes(searchPhrase.trim().toLowerCase()),
    ),
  );
};

const sortMembersFactory =
  (sortColumn: SortColumn, sortDirection: SortDirection) =>
  (a: OrganizationMember, b: OrganizationMember) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (aValue === null || aValue === undefined) {
      return sortDirection === 'asc' ? 1 : -1;
    }
    if (bValue === null || bValue === undefined) {
      return sortDirection === 'asc' ? -1 : 1;
    }

    const comparison = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    return sortDirection === 'asc' ? comparison : -comparison;
  };

const filterMembers = (data: OrganizationMember[], filter: FilterState) => {
  if (filter === 'all') {
    return data;
  }
  return data.filter(m => m.state === filter);
};

export const MemberManagement = () => {
  const { isLoading: isProfileLoading, data: profileData } = useSelector(
    selectUserProfileResult,
  );
  const { isLoading, data } = useOrganizationMembersQuery(null);

  const [activeFilter, setActiveFilter] = useState<FilterState>('all');
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [sortColumn, setSortColumn] = useState<SortColumn>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }
    const stateFilteredData = filterMembers(data, activeFilter);
    const searchFilteredData = searchMembers(searchPhrase, stateFilteredData);
    return [...searchFilteredData].sort(
      sortMembersFactory(sortColumn, sortDirection),
    );
  }, [data, searchPhrase, activeFilter, sortColumn, sortDirection]);

  if (
    !isProfileLoading &&
    !profileData?.memberRoles?.some(r => r.roleId === 'member_admin')
  ) {
    return (
      <Box minHeight="60vh">
        <Typography variant="h1" gutterBottom color="red">
          No Access
        </Typography>
        <Typography variant="body2">
          Please contact{' '}
          <a href={`mailto:${urls.supportEmail}`}>LumiQ support</a> if you
          should have access
        </Typography>
      </Box>
    );
  }
  const isStickyHeader = data && data.length >= 30;

  const noArchivedMemberCount =
    data?.filter(m => m.state !== 'archived').length ?? '...';

  const columns: {
    name: string;
    width?: string | number;
    sortKey: SortColumn;
  }[] = [
    { name: 'Name', sortKey: 'name' },
    { name: 'Login Email', width: '30%', sortKey: 'user_email' },
    { name: 'Access', width: '160px', sortKey: 'state' },
    { name: 'Last Listened', width: '160px', sortKey: 'last_user_listening' },
    {
      name: 'Date Added',
      width: '160px',
      sortKey: 'organization_member_created_at',
    },
  ];

  const handleSort = (column: SortColumn) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  return (
    <Box>
      <Box mb={4} display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box>
            <Typography variant="h1" gutterBottom>
              Members
            </Typography>
            <Typography variant="body2">
              <strong>{noArchivedMemberCount}</strong> Members on your plan
            </Typography>
          </Box>
          <CsvDownload data={data} />
        </Box>
      </Box>
      <Box mb={4}>
        <Box display="flex" justifyContent="space-between">
          <MemberStateFilters
            activeFilter={activeFilter}
            onFilterSelection={filter => setActiveFilter(filter)}
            data={data}
          />
          <Box>
            <TableSearchBox
              placeHolder="Search Members"
              onSearch={search => setSearchPhrase(search)}
            />
          </Box>
        </Box>

        <Box sx={{ overflow: 'hidden' }}>
          <TableContainer
            sx={isStickyHeader ? { maxHeight: 'calc(100vh - 300px)' } : {}}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              stickyHeader={isStickyHeader}
              aria-label="Organization Members">
              <TableHead>
                <TableRow>
                  {columns.map(c => (
                    <TableCell
                      key={c.name}
                      width={c?.width}
                      sx={{ paddingBlock: 2 }}>
                      <TableSortLabel
                        active={sortColumn === c.sortKey}
                        direction={sortDirection}
                        onClick={() => handleSort(c.sortKey)}>
                        <Typography
                          variant="body1"
                          lineHeight="30px"
                          fontWeight={700}>
                          {c.name}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <SkeletonTable rows={5} />
                ) : (
                  filteredData?.map(row => (
                    <TableRow
                      key={row.organization_email}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ paddingBlock: 2 }}>
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          lineHeight="30px">
                          {row.name}
                        </Typography>
                        <Typography variant="body3">
                          {row.organization_email}
                        </Typography>
                      </TableCell>
                      <Typography
                        variant="body3"
                        component={TableCell as React.ElementType}
                        sx={{ paddingBlock: 2 }}>
                        {row.user_email ?? '-'}
                      </Typography>
                      <TableCell sx={{ paddingBlock: 2 }}>
                        <MemberState state={row.state} />
                      </TableCell>
                      <Typography
                        variant="body3"
                        component={TableCell as React.ElementType}
                        sx={{ paddingBlock: 2 }}>
                        {row.last_user_listening ? (
                          <UserFriendlyDateWithTooltip
                            date={row.last_user_listening}
                          />
                        ) : row.state === 'active' ? (
                          <Typography variant="body3" color="salmon">
                            No Usage
                          </Typography>
                        ) : (
                          '-'
                        )}
                      </Typography>
                      <TableCell sx={{ paddingBlock: 2 }}>
                        <UserFriendlyDateWithTooltip
                          date={row.organization_member_created_at}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};
