import { Box, Chip, Tooltip } from '@mui/material';

import { OrganizationMember } from '@/store/member-management-api';

import { mapMemberStateToUiLabel } from './utils';

export type FilterState = 'active' | 'invited' | 'archived' | 'all';

export type Params = {
  data: OrganizationMember[] | undefined;
  activeFilter: FilterState;
  onFilterSelection: (state: FilterState) => void;
};

const FilterChip = ({
  isActive,
  count,
  state,
  label,
  onFilterSelection,
}: {
  isActive: boolean;
  count?: number;
  state: FilterState;
  label: string;
  onFilterSelection: (state: FilterState) => void;
}) => (
  <Tooltip
    title={state === 'all' ? 'Show all Members' : `Only show ${label} Members`}>
    <Chip
      onClick={() => onFilterSelection(state)}
      clickable
      color={isActive ? 'primary' : 'default'}
      variant={isActive ? 'filled' : 'outlined'}
      disabled={count === 0}
      sx={isActive ? { color: 'white', fontWeight: 'bold' } : {}}
      label={
        <>
          {count !== undefined ? (
            <>
              <strong>{count}</strong> {label}
            </>
          ) : (
            label
          )}
        </>
      }
    />
  </Tooltip>
);

export const MemberStateFilters = ({
  onFilterSelection,
  activeFilter,
  data,
}: Params) => (
  <Box mb={4} display="flex" gap={1}>
    <FilterChip
      state="all"
      isActive={activeFilter === 'all'}
      label="All"
      onFilterSelection={onFilterSelection}
    />
    {(['active', 'invited', 'archived'] as const).map(state => (
      <FilterChip
        state={state}
        key={state}
        label={mapMemberStateToUiLabel(state)}
        isActive={activeFilter === state}
        count={data?.filter(m => m.state === state).length}
        onFilterSelection={onFilterSelection}
      />
    ))}
  </Box>
);
