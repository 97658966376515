import * as R from 'ramda';

export type RouteType = {
  PATH: string;
  NAME: string;
  IS_PRIVATE: boolean;
  IS_WITH_APP_LAYOUT: boolean;
  IS_WITH_PLAYER: boolean;
};

export const RoutesConfig = {
  PROFILE: {
    PATH: '/profile',
    NAME: 'Profile',
    IS_PRIVATE: true,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  ROOT: {
    PATH: '/',
    NAME: 'Root',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: true,
  },
  DISCOVER: {
    PATH: '/discover',
    NAME: 'Discover',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  TECHNICAL: {
    PATH: '/technical',
    NAME: 'Technical',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  LEARNING_PATHS: {
    PATH: '/learning-paths',
    NAME: 'Learning Paths',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  LEARNING_PATH: {
    PATH: '/learning-path/:learningPathId',
    NAME: 'Learning Path',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  LOGIN: {
    PATH: '/login',
    NAME: 'Login',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  REGISTER: {
    PATH: '/register',
    NAME: 'Register',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  FORGOT_PASSWORD: {
    PATH: '/forgot-password',
    NAME: 'Forgot Password',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  RESET_PASSWORD: {
    PATH: '/reset-password',
    NAME: 'Reset Password',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  VALIDATE_INVITE: {
    PATH: '/plans/redeem/:inviteToken',
    NAME: 'Validate Invite',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  VALIDATE_OFFER: {
    PATH: '/offers/redeem/:offerId',
    NAME: 'Validate Offer',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  VALIDATE_FREE_TRIAL_OFFER: {
    PATH: '/free-trial/:offerId',
    NAME: 'Validate Free Trial',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  LIBRARY: {
    PATH: '/library',
    NAME: 'Filter Library',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  MY_LUMIQ: {
    PATH: '/my-lumiq',
    NAME: 'My LumiQ',
    IS_PRIVATE: true,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  EPISODE: {
    PATH: '/episodes/:episodeId',
    NAME: 'Episode',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  SEARCH: {
    PATH: '/search',
    NAME: 'Search',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  TRACKER: {
    PATH: '/tracker',
    NAME: 'Tracker',
    IS_PRIVATE: true,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  CPD_REQUIREMENTS: {
    PATH: '/cpd-requirements',
    NAME: 'CPD Requirements',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
  PRICING: {
    PATH: '/pricing',
    NAME: 'Pricing',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  MY_OFFERS: {
    PATH: '/my-offers',
    NAME: 'Pricing',
    IS_PRIVATE: true,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  CPA_CANADA: {
    PATH: '/cpacanada',
    NAME: 'CPA Canada',
    IS_PRIVATE: false,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  CERTIFICATE_DOWNLOAD: {
    PATH: '/download-cert',
    NAME: 'Download Certificate',
    IS_PRIVATE: true,
    IS_WITH_APP_LAYOUT: false,
    IS_WITH_PLAYER: false,
  },
  MEMBER_MANAGEMENT: {
    PATH: '/member-management',
    NAME: 'Members',
    IS_PRIVATE: true,
    IS_WITH_APP_LAYOUT: true,
    IS_WITH_PLAYER: true,
  },
} satisfies Record<string, RouteType>;

export const Routes = R.mapObjIndexed(route => route.PATH, RoutesConfig);

export const PrivateRoutes = R.pipe(
  R.filter((route: RouteType) => route.IS_PRIVATE),
  R.mapObjIndexed((route: RouteType) => route.PATH),
)(RoutesConfig);

export const RoutesWithLayout = R.pipe(
  R.filter((route: RouteType) => route.IS_WITH_APP_LAYOUT),
  R.mapObjIndexed((route: RouteType) => route.PATH),
)(RoutesConfig);

export const RoutesWithPlayer = R.pipe(
  R.filter((route: RouteType) => route.IS_WITH_PLAYER),
  R.mapObjIndexed((route: RouteType) => route.PATH),
)(RoutesConfig);

export const SidebarItems = R.pick(
  ['DISCOVER', 'LEARNING_PATHS', 'LIBRARY', 'TECHNICAL', 'MY_LUMIQ', 'TRACKER'],
  RoutesConfig,
);
