import { fetchOrganizationMembers } from '@/requests';
import { postgrestApi } from '@/store/postgrest-api';

export type OrganizationMember = {
  organization_member_id: string;
  name: string;
  user_email: string;
  organization_email: string;
  state: string;
  plan_id: string;
  plan_assigned_date: string; // date
  usage_limit: number;
  organization_member_created_at: string; // date
  state_updated_at: string; // date
  last_user_listening: string | null;
  all_tags: string[];
};

export const memberManagementApi = postgrestApi.injectEndpoints({
  endpoints: builder => ({
    organizationMembers: builder.query<OrganizationMember[], null>({
      query: fetchOrganizationMembers,
    }),
  }),
});

export const { useOrganizationMembersQuery } = memberManagementApi;
