import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import {
  PlanInfo,
  UpdatePasswordForm,
  UpdatePaymentInfo,
  UpdateUserInfoForm,
} from '@/containers';
import { selectIsB2CUser } from '@/store';

export const Profile = () => {
  const isB2CUser = useSelector(selectIsB2CUser);

  return (
    <>
      <Typography variant="h1">Profile</Typography>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 10,
        }}
        container
        spacing={4}>
        <Grid item xs={false} md={2} />
        <Grid item xs={12} md={8} sx={{ width: '100%' }}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <PlanInfo />
            </Grid>
            <Grid item xs={12}>
              <UpdateUserInfoForm />
            </Grid>
            <Grid item xs={12}>
              <UpdatePasswordForm />
            </Grid>
            {!!isB2CUser && (
              <Grid item xs={12}>
                <UpdatePaymentInfo />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
