import { getActiveTwoForOneSkuId } from '@/containers/Pricing/utils';
import { pricingPersonalPlan, pricingTeamPlan } from '@/images';

const currentYear = new Date().getFullYear();

const EPISODE_COUNT = 900;

export const SKUS = [
  {
    region: 'CAN',
    sku: 'sku_YfSYq',
    name: '5 Hours',
    descriptiveName: '5 Hour CPD Package',
    description: `The perfect package to round out your ${currentYear} compliance`,
    bullets: [
      `Choose from over ${EPISODE_COUNT} episodes, including ethics`,
      'New episodes added weekly',
      'Access to our CPD tracker',
      'Expires in 12 months',
    ],
    top: {
      background: 'orange',
      title: 'Top Up Hours',
    },
    cta: 'Buy Package',
    finePrint: `Your purchase covers 5 hours of your CPD requirements.
      You may use this towards any content on LumiQ, including ethics.
      Your purchase of 5 hours expires in 12 months from the date of purchase.`,
  },
  {
    region: 'CAN',
    sku: 'sku_y13sq',
    name: '10 Hours',
    descriptiveName: '10 Hour CPD Package',
    description:
      'For those who get a few hours at work,' +
      ' but need more to meet the minimum requirements',
    bullets: [
      `Choose from over ${EPISODE_COUNT} episodes, including ethics`,
      'New episodes added weekly',
      'Access to our CPD tracker',
      'Expires in 12 months',
    ],
    top: {
      background: 'orange',
      title: 'Supplemental Package',
    },
    cta: 'Buy Package',
    finePrint: `Your purchase covers 10 hours of your CPD requirements.
      You may use this towards any content on LumiQ, including ethics.
      Your purchase of 10 hours expires in 12 months from the date of purchase.`,
  },
  {
    region: 'CAN',
    sku: 'sku_ttfis',
    name: '20 Hours',
    descriptiveName: '20 Hour CPD Package',
    description:
      'The best package for CPAs who need some hours this year, and some hours next year',
    bullets: [
      `Choose from over ${EPISODE_COUNT} episodes, including ethics`,
      'New episodes added weekly',
      'Access to our CPD tracker',
      'Expires in 12 months',
    ],
    top: {
      background: 'gray',
      title: 'CPD Compliance',
    },
    cta: 'Buy Package',
    finePrint: `Your purchase covers 20 hours of your CPD requirements.
      You may use this towards any content on LumiQ, including ethics.
      Your purchase of 20 hours expires in 12 months from the date of purchase.`,
  },
  {
    region: 'CAN',
    sku: 'sku_bv3ag',
    name: 'Unlimited Calendar Year Subscription',
    descriptiveName: `Unlimited ${currentYear} Subscription`,
    description:
      'Our best value package, giving you unlimited ' +
      'access until the end of this calendar year',
    bullets: [
      'Unlimited access to our entire content library',
      'New episodes added weekly',
      'Access to our CPD tracker',
      'Auto-renews January 1st (calendar year) to keep you fully compliant',
    ],
    showBestValue: !getActiveTwoForOneSkuId(),
    top: {
      background: 'blueToAqua',
      title: 'CPD Pro',
    },
    cta: 'Subscribe',
    finePrint: `This covers your ${currentYear} CPD compliance allowing you to get
      unlimited usage, including ethics, for the rest of the year.
      Your CPD package will auto-renew for ${currentYear + 1} but can be cancelled at any time.`,
  },
  {
    region: 'USA',
    sku: 'sku_pBcW4',
    name: 'Unlimited',
    descriptiveName: 'Unlimited Subscription',
    description:
      'The ideal package to use LumiQ for all of your CPE requirements, ' +
      'while leveraging the platform to learn new skills and grow in your career.',
    bullets: [
      'Auto-renews every 12 months',
      'Full content access',
      'CPE tracker',
    ],
    top: {
      background: 'blueToAqua',
      title: 'Unlimited',
    },
    cta: 'Buy Package',
    finePrint: `The Unlimited Subscription allows you to earn
      unlimited credits. It expires and auto-renews 12 months from the date of
      purchase but can be cancelled at any time.`,
  },
  {
    region: 'USA',
    sku: null,
    name: 'Custom',
    description:
      'Whether your team needs full compliance or a bucket of credits to ' +
      'split amongst a group, our team packages are built to suit any of your needs.',
    bullets: [
      'Colleague sharing feature',
      'Quarterly usage reports',
      'Multiple business models',
      'Dedicated customer service manager',
    ],
    top: {
      background: 'gray',
      title: 'LumiQ For Teams',
    },
    cta: 'Contact Us',
  },
];

export const CAN_SKUS = SKUS.filter(sku => sku.region === 'CAN');
export const USA_SKUS = SKUS.filter(sku => sku.region === 'USA');

export const PERSONAL_OFFER = {
  description: 'Our best value package, giving you unlimited access to LumiQ',
  bullets: [
    'Unlimited access to our entire content library',
    'New episodes added weekly',
    'Access to our PD tracker',
    'Auto-renews at the end of your subscription period',
  ],
  top: {
    background: 'blueToAqua',
    title: 'Special Offer',
  },
  cta: 'Claim Offer',
  finePrint:
    `This covers your LumiQ subscription allowing you to listen to any episode,` +
    ` including ethics and videos. Your subscription will auto-renew at the end of the term but can be cancelled at any time.`,
};

export const TWO_FOR_ONE_OFFER = {
  name: `Unlimited ${currentYear} and ${currentYear + 1} Subscription`,
  descriptiveName: `Unlimited ${currentYear} + ${currentYear + 1} Subscription`,
  description:
    'Our best value package, giving you unlimited ' +
    `access for the rest of ${currentYear} and all of ${currentYear + 1}`,
  bullets: [
    'Unlimited access to our entire content library',
    'New episodes added weekly',
    'Access to our CPD tracker',
    `Auto-renews January 1st ${currentYear + 2} to keep you fully compliant`,
  ],
  showBestValue: true,
  top: {
    background: 'orange',
    title: 'Two for One',
  },
  cta: 'Claim Offer',
  finePrint: `This covers your ${currentYear} and ${currentYear + 1} CPD compliance allowing you to get
    unlimited usage, including ethics, until the end of ${currentYear + 1}.
    Your CPD package will auto-renew for ${currentYear + 2} but can be cancelled at any time.`,
};

export const PLAN_OPTIONS = [
  {
    name: 'Personal Plans',
    color: 'teal30',
    image: pricingPersonalPlan,
    description:
      'All of our personal plans include access to our entire library' +
      ` of ${EPISODE_COUNT}+ episodes, including ethics.`,
    bullets: [
      '5 Hour CPD Package',
      '10 Hour CPD Package',
      '20 Hour CPD Package',
      '2024 CPD Subscription',
    ],
    cta: 'View Personal Plans',
  },
  {
    name: 'For Teams',
    color: 'neutral30',
    image: pricingTeamPlan,
    description:
      'Reach out to learn more about our plans and ' +
      "promotions and we'd be happy to assist you!",
    bullets: [
      'Premium Team Plans',
      'Shared Team Hours',
      'Ethics For Your Team',
    ],
    cta: 'Contact Us',
  },
];
