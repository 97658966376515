import { createSvgIcon } from '@mui/material/utils';

export const DownloadIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3V14.5M12 14.5L7 10M12 14.5L17 10M4 16.6066V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V16.6066"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  'Download',
);
